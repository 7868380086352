import { _post } from './index'

//上传图片
export const upload = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach(key => {
    formData.append(key, data[key])
  });
  let req = {
    data: formData,
    url: 'admin/upload_img'
  };
  return _post(req);
};


