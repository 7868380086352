import React from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  settingList: {
    justifyContent: 'space-between',
    paddingBottom: '0.2rem',
    paddingTop: '0.2rem',
    alignItems: 'center',
    display: 'flex',
    color: 'rgba(0, 0, 0, 0.45)',
    width: '100%',
    fontWeight:'normal',
    background: 'white',
    borderBottom: '1px #ddd solid'
  },
  labelText: {
    fontSize: '0.9rem',
    marginLeft: '3%'
  },
  input: {
    width: '50%',
    fontSize:'14px',
    marginRight: '2%',
    textAlign: 'right',
    '&::-webkit-input-placeholder': {
      color: '#ddd'
    }
  }
});

export default function CustomInput({ label, value, onChange, required, disabled, disablePast }) {
  const classes = useStyles();

  return (
    <div className={classes.settingList}>
      <span className={classes.labelText}>
        {label}
        {required && <span style={{ color: 'red' }}>*</span>}
      </span>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          disableToolbar
          disablePast={disablePast}
          disabled={disabled}
          style={{ margin: 0, padding: 0 }}
          margin='normal'
          format='MM/dd/yyyy'
          value={value}
          onChange={onChange}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}
