import React from 'react';

import { connect } from 'react-redux';
import action from '../../store/action';

import OneLoan from './OneLoan';

import FixedButton from '../../components/FixedButton';

function LoanList({ loans, loan_ids, setLoanIds, onClick }) {
  const pushLoanToUser = id => {
    if (loan_ids.some(i => i === id)) {
      setLoanIds(loan_ids.filter(item => item !== id));
    } else {
      setLoanIds([...loan_ids, id]);
    }
  };

  return (
    <React.Fragment>
      <React.Fragment>
        {' '}
        <div style={{ width: '100%' }}>
          {loans.length > 0 &&
            loans.map(i => (
              <OneLoan
                pushLoanToUser={pushLoanToUser}
                checked={loan_ids.some(item => i.id === item)}
                loan={i}
                key={i.id}
              />
            ))}
        </div>
      </React.Fragment>
      <FixedButton onClick={onClick}> Done </FixedButton>
    </React.Fragment>
  );
}

export default connect(state => state.loan, { ...action.loan, ...action.globalLoading })(LoanList);
