import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Fab } from '@material-ui/core';
import CustomForm from '../../components/CustomForm';
import CustomInput from '../../components/CustomInput';
import CustomDatePicker from '../../components/CustomDatePicker';
import CustomSelect from '../../components/CustomSelect';
import useFormInput from '../../hooks/useFormInput';
import useNumberInput from '../../hooks/useNumberInput';
import { createTransactionApi } from '../../api/loan';
import { getFullDate } from '../../utils/date';
const useStyles = makeStyles(theme => ({
  paper: {
    width: '90%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    boxShadow: theme.shadows[5],
    padding: '20px 20px 120px 20px',
    margin: 'auto',
    marginTop: '25vh'
  },
  fab: {
    marginTop: '10vh',
    padding:'0 20%',
    color: 'white',
    background: '#1a75d2',
  }
}));

const options = [
  { value: 'payment', label: 'Payment' },
  { value: 'withdrawal', label: 'Withdrawal' },
  { value: 'line_fee', label: 'Line Fee' },
  { value: 'establishment_fee', label: 'Establishment Fee' },
  { value: 'credit_journal', label: 'Credit Journal' }
];

function CreateTransaction({ global_action_loading, loan_id, addNewTransaction, handleCreateCloseModal }) {
  const classes = useStyles();
  const [count, setCount] = useState(0);
  const [date, setDate] = useState(new Date());
  const amount = useNumberInput('', count);
  const reference = useFormInput('', count);
  const type = useFormInput('payment');

  const handleCreateTransaction = () => {
    if (!date || !amount.value || !type.value || !loan_id) {
      alert('Please Enter Required Field');
      return;
    }

    global_action_loading();
    const data = {
      loan_id,
      date: getFullDate(date),
      amount: Number(amount.value),
      type: type.value,
      reference: reference.value
    };

    createTransactionApi(data)
      .then(res => {
        if (res.data.status === 200) {
          const change_amount =
            type.value === 'payment' || type.value === 'credit_journal' ? -Number(amount.value) : Number(amount.value);
          addNewTransaction(data, change_amount);
          setCount(count + 1);
          handleCreateCloseModal();
        }
        alert(res.data.message);
        global_action_loading(false);
      })
      .catch(err => {
        alert('err');
        global_action_loading(false);
      });
  };
  return (
    <div className={classes.paper}>
      <CustomForm>
        <CustomSelect {...type} label='Type' options={options} />
        <CustomDatePicker
          label='Date'
          value={date}
          onChange={date => {
            setDate(date);
          }}
        />
        <CustomInput {...amount} label='Amount' placeholder='Amount' required />
        <CustomInput {...reference} label='Reference' placeholder='Reference' required />
        <div style={{textAlign:'center'}}>
        <Fab variant='extended' onClick={handleCreateTransaction} className={classes.fab}>
          Done
        </Fab></div>
      </CustomForm>
    </div>
  );
}

export default CreateTransaction;
