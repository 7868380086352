import React from 'react';
import LoanList from './LoanList';
// import LoanSearch from './LoanSearch';
import action from '../../store/action';
import { connect } from 'react-redux';

function Loan({ searching, location, auth, remove_auth }) {
  if (!auth) {
    remove_auth();
  }

  return (
    <React.Fragment>
      {/* <LoanSearch searching={searching} />
      <br /> */}
      <LoanList searching={searching} location={location} auth={auth} />
    </React.Fragment>
  );
}

export default connect(state => state.person, action.person)(Loan);
