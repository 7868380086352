import React, { useState, useEffect } from 'react';
import { getLoanApi } from '../../api/loan';
import { Grid, Button, Modal, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import action from '../../store/action/index';
import OneLoan from '../Loan/OneLoan';

import moment from 'moment';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import CreateTransaction from './CreateTransaction';

const useStyles = makeStyles({
  title: {
    height: '22px',
    fontSize: '16px',
    fontWeight: '500',
    color: 'rgba(0, 0, 0, 0.85)',
    padding: '0.4em 0.8em'
  },
  card: {
    padding: '2%',
    margin: '8px 2% 4px 2%',
    border: '1px solid #eeeeee',
    borderRadius: '8px',
    background: 'white'
  },
  numberStyle: {
    height: 21,
    fontWeight: 'normal',
    display: 'flex',
    fontSize: '16px',
    justifyContent: 'space-between',
    color: 'rgba(0,0,0,0.8)'
  },
  info: {
    color: 'rgba(0, 0, 0, 0.45)',
    fontSize: '12px',
    marginTop: 5
  },
  btnStyle: {
    textAlign: 'right',
    marginRight: '2.5%'
  },
  btnEdit: {
    marginRight: '1em',
    color: '#007bff',
    border: '0.5px solid #007bff'
  },
  btnDelete: {
    color: '#dc3545',
    border: '0.5px solid #dc3545'
  },
  alignEven: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  primaryText: {
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: '12px',
    fontWeight: 'normal'
  }
});
const Details = React.memo(({ location, global_action_loading, history, auth }) => {
  const classes = useStyles();

  const [loan, setLoan] = useState({});

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const handleCreateOpen = () => {
    setOpenCreateModal(true);
  };
  const handleCreateCloseModal = () => {
    setOpenCreateModal(false);
  };

  const addNewTransaction = (data, amount) => {
    setLoan({
      ...loan,
      current_balance: loan.current_balance + amount,
      transactions: [...loan.transactions, data]
    });
  };
  const loan_id = Number(location.search.split('=')[1]);
  useEffect(() => {
    global_action_loading();
    getLoanApi({ id: loan_id, role: auth })
      .then(res => {
        global_action_loading(false);
        if (res.data.status === 200) {
          setLoan(res.data.data);
        } else {
          history.goBack();
        }
      })
      .catch(err => {
        global_action_loading(false);
        history.goBack();
      });
  }, [global_action_loading, loan_id, history, auth]);

  return (
    <React.Fragment>
      <Grid container justify='center'>
        <Grid item md={6} sm={8} xs={12} lg={4}>
          {loan.name ? (
            <React.Fragment>
              <OneLoan loan={loan} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0.2em 0'
                  }}
                >
                  <div className={classes.title}>Transactions:</div>
                  <div>
                    {auth === 'admin' && (
                      <Button
                        style={{
                          background: 'transparent',
                          color: '#007bff'
                        }}
                        onClick={handleCreateOpen}
                      >
                        <AddCircleOutlineIcon />
                        Add
                      </Button>
                    )}
                  </div>
                </div>
                {loan.transactions.map((i, index) => (
                  <div key={index}>
                    <div className={classes.card}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between'
                        }}
                      >
                        {}
                        <div className={classes.numberStyle}>
                          <div>{moment(i.date + '').format('ll')}</div>
                          <div>${i.amount.toLocaleString()}</div>
                        </div>
                      </div>
                      <div className={classes.info}>
                        <div>Reference:</div>
                        <div>Notes: {i.type}</div>
                        <div>Balance:</div>
                      </div>
                    </div>
                    {auth === 'admin' && (
                      <div className={classes.btnStyle}>
                        <Button variant='outlined' size='small' className={classes.btnEdit}>
                          Edit
                        </Button>
                        <Button variant='outlined' size='small' className={classes.btnDelete}>
                          Delete
                        </Button>
                      </div>
                    )}
                  </div>
                ))}
                <div
                  className={classes.title}
                  style={{
                    margin: '0 0 1em'
                  }}
                >
                  Statements:
                </div>
                {loan.statements.map((i, index) => (
                  <div className={classes.card} key={index}>
                    <div className={classes.numberStyle}>{moment(i.date + '').format('ll')}</div>
                    <div
                      className={classes.info}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div className={classes.alignEven}>
                        <div>Loan Withdrawal:</div>
                        <div className={classes.primaryText}>{i.withdrawal.toLocaleString()}</div>
                      </div>
                      <div className={classes.alignEven}>
                        <div>Establishment Fee:</div>
                        <div className={classes.primaryText}>{i.establishment_fee.toLocaleString()}</div>
                      </div>
                      <div className={classes.alignEven}>
                        <div>Line fee:</div>
                        <div className={classes.primaryText}>{i.line_fee.toLocaleString()}</div>
                      </div>
                      <div className={classes.alignEven}>
                        <div>Interest:</div>
                        <div className={classes.primaryText}>{i.interest.toLocaleString()}</div>
                      </div>
                      <div className={classes.alignEven}>
                        <div>Paid:</div>
                        <div className={classes.primaryText}>{i.payment.toLocaleString()}</div>
                      </div>
                    </div>
                    <div
                      style={{
                        margin: '1em 0'
                      }}
                    >
                      <Divider />
                    </div>
                    <div className={classes.alignEven}>
                      <div className={classes.primaryText}>Outstanding Balance:</div>
                      <div className={classes.numberStyle}>${i.current_balance.toLocaleString()}</div>
                    </div>
                  </div>
                ))}
              </div>
            </React.Fragment>
          ) : (
            <div></div>
          )}
        </Grid>
      </Grid>
      {auth === 'admin' && (
        <Modal
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          open={openCreateModal}
          onClose={handleCreateCloseModal}
        >
          <div>
            <CreateTransaction
              global_action_loading={global_action_loading}
              loan_id={loan_id}
              addNewTransaction={addNewTransaction}
              handleCreateCloseModal={handleCreateCloseModal}
            />
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
});

export default connect(null, action.globalLoading)(Details);
