import React, { useState, useEffect } from 'react';

import CustomDatePicker from '../../components/CustomDatePicker';
import CustomForm from '../../components/CustomForm';
import CustomInput from '../../components/CustomInput';
import CustomSelect from '../../components/CustomSelect';

import FixedButton from '../../components/FixedButton';
import useFormInput from '../../hooks/useFormInput';

import useNumberInput from '../../hooks/useNumberInput';
import action from '../../store/action';
import { connect } from 'react-redux';
import { createLoanApi } from '../../api/loan';

import { createAccountList } from '../../utils/utils';
import { getFullDate } from '../../utils/date';
import { getAccountMethod } from '../../utils/api';

import { Typography, Grid } from '@material-ui/core';
const today = new Date();

function LoanCreate({ global_action_loading, add_loan, set_accounts, accountsIsGet, accounts }) {
  const [maturity_date, setTimeMaturity] = useState(today);
  const [open_date, setTimeOpen] = useState(today);
  const [count, setCount] = useState(0);

  const accountList = createAccountList(accounts);
  const [account_id, setAccountId] = useState('');
  const name = useFormInput('', count);
  const establishment_fee = useNumberInput('', count);
  const withdrawal = useNumberInput('', count);
  const interest_rate = useNumberInput('', count);
  useEffect(() => {
    if (!accountsIsGet) {
      getAccountMethod(set_accounts, global_action_loading);
    }
  }, [accountsIsGet, set_accounts, global_action_loading]);

  const submitLoan = () => {
    if (
      !account_id ||
      !withdrawal.value ||
      !name.value ||
      !interest_rate.value ||
      !open_date ||
      !maturity_date ||
      !establishment_fee.value
    ) {
      alert('Please Enter Required Field');
      return;
    }
    global_action_loading();

    const data = {
      account_id,
      name: name.value,
      withdrawal: Number(withdrawal.value),
      establishment_fee: Number(establishment_fee.value),
      interest_rate: Number((interest_rate.value / 100).toFixed(5)),
      open_date: getFullDate(open_date),
      maturity_date: getFullDate(maturity_date),
      current_balance: Number((Number(withdrawal.value) + Number(establishment_fee.value)).toFixed(2))
    };

    createLoanApi(data)
      .then(res => {
        if (res.data.status === 200) {
          const oneLoan = {
            ...data,
            id: res.data.data,
            account_logo: res.data.account_logo,
            account_name: res.data.account_name
          };

          add_loan(oneLoan);

          setCount(count + 1);
          alert('Added Successfully!');
        } else {
          alert(res.data.message);
        }
        global_action_loading(false);
      })
      .catch(err => {
        alert('Error');
        global_action_loading(false);
      });
  };

  return (
    <React.Fragment>
      <Grid container justify='center'>
        <Grid item xs={12} sm={8} md={4} lg={4}>
          <CustomForm>
            <CustomSelect
              value={account_id}
              onChange={event => {
                setAccountId(event.target.value);
              }}
              label='Client'
              required
              options={accountList}
            />
            <CustomInput {...name} label='Loan Name' placeholder='Enter' />
            <CustomInput label='Loan ID' placeholder='Enter' required />
            <CustomDatePicker
              label='Open Date'
              value={open_date}
              onChange={date => {
                setTimeOpen(date);
              }}
            />
            <CustomDatePicker
              label='Maturity Date'
              value={maturity_date}
              onChange={date => {
                setTimeMaturity(date);
              }}
              disablePast
            />
            <CustomInput {...interest_rate} label='Interest(%)' placeholder='Enter' required />
            <CustomInput {...establishment_fee} label='Establishment' placeholder='Enter' required />
            <CustomInput {...withdrawal} label='Withdrawal' placeholder='Enter' required />
          </CustomForm>
          <Typography
            variant='body2'
            component='div'
            style={{
              marginLeft: '10px',
              color: 'rgba(0,0,0,0.25)',
              fontWeight: 'normal'
            }}
          >
            Line Fee
          </Typography>
          {/* <CustomSelect value={null} label='Repeat' options={null}/> */}
        </Grid>
      </Grid>
      <FixedButton onClick={submitLoan}>Done</FixedButton>
    </React.Fragment>
  );
}

export default connect(state => state.account, {
  ...action.globalLoading,
  ...action.account,
  ...action.loan
})(LoanCreate);
