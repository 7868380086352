import User from './views/User';
import UserCreate from './views/UserCreate';
import UserDetail from './views/UserDetail';
import Account from './views/Account';
import AccountCreate from './views/AccountCreate';
import AccountDetail from './views/AccountDetail';
import Loan from './views/Loan';
import LoanCreate from './views/LoanCreate';
import LoanDetail from './views/LoanDetail';
import LoanList from './views/LoanList';
const routes = [
  {
    path: '/user',
    name: 'User Management',
    auth: 'admin',
    component: User
  },
  {
    path: '/user_create',
    component: UserCreate
  },
  {
    path: '/user_detail',
    component: UserDetail
  },
  {
    path: '/account',
    name: 'Account Management',
    auth: 'admin',
    component: Account
  },
  {
    path: '/account_create',
    component: AccountCreate
  },
  {
    path: '/account_detail',
    component: AccountDetail
  },
  {
    path: '/loan',
    name: 'Loan Management',
    component: Loan
  },
  {
    path: '/loan_list',

    component: LoanList
  },
  {
    path: '/loan_create',
    component: LoanCreate
  },
  {
    path: '/loan_detail',
    component: LoanDetail
  }
];

export default routes;
