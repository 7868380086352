const accountStyle = theme => ({
    card: {
        margin: '9px 15px 0',
        border: '1px solid #eeeeee',
        borderRadius: '8px',
        background: 'white',
        height: '60px',
        padding: '10px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    title: {
        fontSize: '16px',
        color: 'rgba(0,0,0,0.85)',
        fontWeight: 'normal',
        lineHeight: '2.6',
        marginLeft: '8px'
    },
    avatarImg: {
        width: 40,
        height: 40
    },
    goTo: {
        color: 'rgba(0,0,0,0.45)',
        textAlign: 'right',
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    headerTitle: {
        fontSize: '18px',
        color: 'rgba(0, 0, 0, 0.85)',
        fontWeight: 'normal',
        marginLeft: '-6vw'
    },
    headerSpace: {
        marginTop: '2vh',
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    },
    bigAvatar: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        width: 60,
        height: 60
    },
    sectionTitle: {
        fontSize: '14px',
        fontWeight: '500',
        color: 'rgba(0, 0, 0, 0.85)',
        margin: '0 0.7rem 1rem'
    }
})

export default accountStyle;