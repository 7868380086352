import * as TYPES from '../action-type';

export default function user(
  state = {
    users: [],
    usersIsGet: false
  },
  action
) {
  switch (action.type) {
    case TYPES.ADD_USER:
      state = JSON.parse(JSON.stringify(state));
      state.users = [...state.users, action.user];
      break;
    case TYPES.SET_USERS:
      state = JSON.parse(JSON.stringify(state));
      state.users = action.users;
      state.usersIsGet = true;
      break;
    case TYPES.UPDATE_USER:
      state = JSON.parse(JSON.stringify(state));
      state.users = state.users.map(i => {
        if (i.id === action.user.id) {
          return { ...i, ...action.user };
        } else {
          return i;
        }
      });
      break;
    default:
      break;
  }
  return state;
}
