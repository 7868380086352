import * as TYPES from '../action-type';

export default {
  set_loans(loans) {
    return {
      type: TYPES.SET_LOANS,
      loans
    };
  },
  add_loan(loan) {
    return {
      type: TYPES.ADD_LOAN,
      loan
    };
  },
  need_get() {
    return {
      type: TYPES.NEED_GET
    };
  },
  set_search_loans(loans) {
    return {
      type: TYPES.SET_SEARCH_LOANS,
      loans
    };
  },
  cancel_searching() {
    return {
      type: TYPES.CANCEL_SEARCHING
    };
  }
};
