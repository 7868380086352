import { useState, useEffect } from 'react';

const useNumberInput = (init, count) => {
  const [value, setValue] = useState(init);
  useEffect(() => {
    setValue(init);
  }, [count, init]);
  const onChange = e => {
    let value = e.target.value;

    if (isNaN(value)) {
      value = value.replace(/\D+/, '');
    }

    if (value.lastIndexOf('.') > -1 && value.indexOf('.') !== value.lastIndexOf('.')) {
      value = value.substr(0, value.lastIndexOf('.'));
    }

    if (value.indexOf('.') > -1 && value.toString().split('.')[1].length > 2) {
      value = Number(value)
        .toFixed(3)
        .toString();
    }

    setValue(value);
  };

  return { value, onChange };
};

export default useNumberInput;
