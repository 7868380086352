import * as TYPES from '../action-type';

export default {
  set_users(users) {
    return {
      type: TYPES.SET_USERS,
      users
    };
  },
  add_user(user) {
    return {
      type: TYPES.ADD_USER,
      user
    };
  },
  update_user(user) {
    return {
      type: TYPES.UPDATE_USER,
      user
    };
  }
};
