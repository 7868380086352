import React from 'react';
import AdminAuthWrapper from '../../hoc/AdminAuthWrapper';
import AccountDetail from './AccountDetail';
export default function AccountDetailPage() {
    return (
        <AdminAuthWrapper>
            <AccountDetail/>
        </AdminAuthWrapper>
    );
}
