import React from 'react';
import CreateForm from './CreateForm';
import AdminAuthWrapper from '../../hoc/AdminAuthWrapper';
function UserCreate() {
  return (
    <AdminAuthWrapper>
      <CreateForm />
    </AdminAuthWrapper>
  );
}

export default UserCreate;
