import React, { useState, useEffect } from 'react';
import LoanList from '../LoanList';

import ImageUpload from '../../components/ImageUpload';
import CustomForm from '../../components/CustomForm';
import CustomInput from '../../components/CustomInput';
import OneLoan from '../LoanList/OneLoan';
import FixedButton from '../../components/FixedButton';
import useFormInput from '../../hooks/useFormInput';
import action from '../../store/action';
import { connect } from 'react-redux';
import { upload } from '../../api/upload';
import { createUserApi } from '../../api/user';
import config from '../../config';
import { validEmail } from '../../utils/regex';
import { findLoans } from '../../utils/utils';
import { getLoanMethod } from '../../utils/api';
import { Grid } from '@material-ui/core';

function UserCreate({ global_action_loading, add_user, loansIsGet, set_loans, loans }) {
  const [loan_ids, setLoanIds] = useState([]);
  const [userLoans, setUserLoans] = useState([]);
  const [file, setFile] = useState();
  const [count, setCount] = useState(0);
  const first_name = useFormInput('', count);
  const last_name = useFormInput('', count);
  const title = useFormInput('', count);
  const email = useFormInput('', count);
  const phone = useFormInput('', count);
  const password = useFormInput('', count);
  const company = useFormInput('', count);
  const [loanListDisplay, setLoanListDisplay] = useState(false);
  useEffect(() => {
    if (!loansIsGet) {
      getLoanMethod(set_loans, global_action_loading);
    }
  }, [loansIsGet, set_loans, global_action_loading]);
  const pushLoanToUser = id => {
    setLoanIds(loan_ids.filter(loan_id => loan_id !== id));
    setUserLoans(userLoans.filter(loan => loan.id !== id));
  };

  useEffect(() => {
    setUserLoans(findLoans(loans, loan_ids));
  }, [loans, loan_ids]);
  const submitUser = () => {
    if (!phone.value || !last_name.value || !first_name.value || !email.value) {
      alert('Please Enter Required Field');
      return;
    }
    if (!validEmail(email.value)) {
      alert('Wrong Email Format');
      return;
    }
    if (!file) {
      alert('Avatar Needed');
      return;
    }
    global_action_loading();
    const data = {
      title: title.value,
      company: company.value,
      phone: phone.value,
      first_name: first_name.value,
      last_name: last_name.value,
      email: email.value,
      password: password.value,
      loan_ids
    };

    upload({ file })
      .then(res => {
        if (res.data.status === 200) {
          const url = config.baseURL + 'uploads/' + res.data.filename;
          return url;
        } else {
          throw new Error('Image Update failed');
        }
      })
      .then(url => {
        data.avatar = url;
        return createUserApi(data);
      })
      .then(res => {
        if (res.data.status === 200) {
          data.id = res.data.data;
          add_user(data);
          setCount(count + 1);
          setFile();
          setLoanIds([]);
        }
        alert(res.data.message);
        global_action_loading(false);
      })
      .catch(err => {
        alert('Error');
        global_action_loading(false);
      });
  };

  return (
    <React.Fragment>
      <Grid container justify='center'>
        <Grid item md={4} sm={8} xs={12} lg={4}>
          <div
            style={{
              display: loanListDisplay ? 'none' : 'block'
            }}
          >
            <ImageUpload file={file} setFile={setFile} />
            <br />
            <CustomForm>
              <CustomInput {...first_name} label='First Name' placeholder='First Name' required />
              <CustomInput {...last_name} label='Last Name' placeholder='Last Name' required />
              <CustomInput {...company} label='Company' placeholder='Company' required />
              <CustomInput {...title} label='Title' placeholder='Title In Company' required />
              <CustomInput {...phone} label='Phone' placeholder='User Phone' required />
              <CustomInput {...email} label='Email' placeholder='Email Address For Login' required />
              <CustomInput {...password} label='Password' placeholder='Password' required />
            </CustomForm>
            loan access{' '}
            <button
              onClick={() => {
                setLoanListDisplay(true);
              }}
              style={{
                marginLeft: '50%',
                color: 'blue',
                fontSize: 20
              }}
            >
              +
            </button>
            <div
              style={{
                width: '100%'
              }}
            >
              {userLoans.length > 0 &&
                userLoans.map(i => (
                  <OneLoan
                    pushLoanToUser={pushLoanToUser}
                    checked={loan_ids.some(item => i.id === item)}
                    loan={i}
                    key={i.id}
                  />
                ))}
            </div>
          </div>
          <div
            style={{
              display: loanListDisplay ? 'block' : 'none'
            }}
          >
            <LoanList
              onClick={() => {
                setLoanListDisplay(false);
              }}
              loan_ids={loan_ids}
              setLoanIds={setLoanIds}
              loans={userLoans}
            />
          </div>
        </Grid>
      </Grid>
      <FixedButton onClick={submitUser}>Add User</FixedButton>
    </React.Fragment>
  );
}

export default connect(state => state.loan, {
  ...action.globalLoading,
  ...action.user,
  ...action.loan
})(UserCreate);
