import React, { useState } from 'react';

import ImageUpload from '../../components/ImageUpload';
import CustomForm from '../../components/CustomForm';
import CustomInput from '../../components/CustomInput';
import FixedButton from '../../components/FixedButton';
import useFormInput from '../../hooks/useFormInput';

import action from '../../store/action';
import { connect } from 'react-redux';
import { upload } from '../../api/upload';
import { createAccountApi } from '../../api/account';
import config from '../../config';

import { makeStyles } from '@material-ui/core/styles';
import styles from '../../components/layout/AccountStyle/accountStyle';

import { Grid } from '@material-ui/core';

const useStyles = makeStyles(styles);

function AccountCreate({ global_action_loading, add_account }) {
  const classes = useStyles();

  const [count, setCount] = useState(0);
  const [file, setFile] = useState();

  const name = useFormInput('', count);
  const address = useFormInput('', count);
  const phone = useFormInput('', count);
  const solicitor_name = useFormInput('', count);
  const solicitor_email = useFormInput('', count);
  const solicitor_phone = useFormInput('', count);
  const account_email = useFormInput('', count);
  const account_phone = useFormInput('', count);
  const account_name = useFormInput('', count);

  const submitAccount = () => {
    if (!phone.value || !name.value || !address.value) {
      alert('wrong');
      return;
    }
    if (!file) {
      alert('need  update logo');
      return;
    }
    global_action_loading();
    const data = {
      phone: phone.value,
      name: name.value,
      address: address.value,
      solicitor_email: solicitor_email.value,
      solicitor_phone: solicitor_phone.value,
      solicitor_name: solicitor_name.value,
      account_email: account_email.value,
      account_phone: account_phone.value,
      account_name: account_name.value
    };

    upload({ file })
      .then(res => {
        const url = config.baseURL + 'uploads/' + res.data.filename;
        return url;
      })
      .then(url => {
        data.logo = url;
        return createAccountApi(data);
      })
      .then(res => {
        if (res.data.status === 200) {
          data.id = res.data.data;
          add_account(data);
          setCount(count + 1);
          setFile();
        }
        alert(res.data.message);

        global_action_loading(false);
      })
      .catch(err => {
        global_action_loading(false);
      });
  };

  return (
    <React.Fragment>
      <Grid container justify='center'>
        <Grid item md={4} sm={8} xs={12} lg={4}>
          <div className={classes.headerSpace}>
            <ImageUpload file={file} setFile={setFile} className={classes.bigAvatar} />
          </div>
          <br />
          <CustomForm>
            <CustomInput {...name} label='Name' placeholder='Enter account name' required />
            <CustomInput {...address} label='Address' placeholder='Enter account address' />
            <CustomInput {...phone} label='Phone' placeholder='Enter account phone' />
          </CustomForm>
          <div className={classes.sectionTitle}>Solicitor</div>
          <CustomForm>
            <CustomInput {...solicitor_name} label='Name' placeholder='Enter solicitor name' required />
            <CustomInput {...solicitor_email} label='Email' placeholder='Enter solicitor email' />
            <CustomInput {...solicitor_phone} label='Phone' placeholder='Enter solicitor phone' />
          </CustomForm>
          <div className={classes.sectionTitle}>Account</div>
          <CustomForm>
            <CustomInput {...account_name} label='Name' placeholder='Enter account name' required />
            <CustomInput {...account_email} label='Email' placeholder='Enter account email' />
            <CustomInput {...account_phone} label='Phone' placeholder='Enter account phone' />
          </CustomForm>
        </Grid>
      </Grid>
      <FixedButton onClick={submitAccount}>Add Account</FixedButton>
    </React.Fragment>
  );
}

export default connect(null, {
  ...action.globalLoading,
  ...action.account
})(AccountCreate);
