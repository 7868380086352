import { _post } from './index';

export const loginUserApi = data => {
  let req = {
    data,
    url: 'user/login'
  };
  return _post(req);
};

export const logoutUSerApi = () => {
  let req = {
    url: 'user/logout'
  };
  return _post(req);
};
