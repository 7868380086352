import { combineReducers } from 'redux';

import loan from './loan';
import globalLoading from './globalLoading';
import user from './user';
import account from './account';
import person from './person';

let reducer = combineReducers({ account, globalLoading, user, loan, person });

export default reducer;
