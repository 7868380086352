import React from 'react';
import { Button } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  confirmWraper: {
    width: '100%',
    position: 'fixed',
    bottom: '1.5rem',
    textAlign: 'center'
  },
  confirmButton: {
    padding: '10px 2.5em',
    background: '#007bff',
    borderRadius: 22,
    color: '#fff',
    '&:hover': {
      background: '#2a85e2'
    }
  }
});

export default function FixedButton({ onClick, children }) {
  const classes = useStyles();

  return (
    <div className={classes.confirmWraper}>
      <Button onClick={onClick} className={classes.confirmButton}>
        {children}
      </Button>
    </div>
  );
}
