import React from 'react';
import UserDetail from './UserDetail';
function UserDetailPage(props) {
  return (
    <React.Fragment>
      <UserDetail />
    </React.Fragment>
  );
}

export default UserDetailPage;
