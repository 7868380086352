import * as TYPES from '../action-type';
import { changeLoans } from '../../utils/utils';

export default function loan(
  state = {
    loans: [],
    searchingloans: [],
    searching: false,
    loansIsGet: false
  },
  action
) {
  switch (action.type) {
    case TYPES.ADD_LOAN:
      state = JSON.parse(JSON.stringify(state));
      state.loans = [...state.loans, action.loan];
      break;
    case TYPES.SET_LOANS:
      state = JSON.parse(JSON.stringify(state));
      state.loans = changeLoans(action.loans);
      state.loansIsGet = true;
      break;
    case TYPES.SET_SEARCH_LOANS:
      state = {
        ...state,
        searchingloans: action.loans,
        searching: true
      };
      break;
    case TYPES.NEED_GET:
      state = {
        ...state,
        loansIsGet: false
      };
      break;
    case TYPES.CANCEL_SEARCHING:
      state = {
        ...state,
        searching: false
      };
      break;
    default:
      break;
  }
  return state;
}
