import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const BackButton = ({ history }) => {
  const goBack = () => {
    history.goBack();
  };
  return (
    <Button onClick={goBack}>
      <ArrowBackIosIcon color='action' />
    </Button>
  );
};

export default withRouter(BackButton);
