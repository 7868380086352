import moment from 'moment';
export const getFullDate = date => {
  const year = date.getFullYear().toString();
  const month = date.getMonth() + 1 > 9 ? (date.getMonth() + 1).toString() : '0' + (date.getMonth() + 1);
  const day = date.getDate() > 9 ? date.getDate().toString() : '0' + date.getDate();
  return Number(year + month + day);
};

export const calculateTwoFullDate = fullDate => {
  return moment(fullDate + '', 'YYYYMMDD').diff(moment(), 'days');
};
