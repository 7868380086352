import React, { useEffect } from 'react';
import FixedButton from '../../components/FixedButton';
import { connect } from 'react-redux';
import action from '../../store/action';

import OneUser from './OneUser';
import { Link } from 'react-router-dom';
import { getUserMethod } from '../../utils/api';
function UserList({ global_action_loading, users, set_users, usersIsGet }) {
  useEffect(() => {
    if (!usersIsGet) {
      getUserMethod(set_users, global_action_loading);
    }
  }, [usersIsGet, set_users, global_action_loading]);

  return (
    <React.Fragment>
      <React.Fragment>
        <div style={{ width: '100%' }}>
          {users.map(i => (
            <OneUser user={i} key={i.id} />
          ))}
        </div>
      </React.Fragment>

      <FixedButton onClick={() => {}}>
        <Link to='/user_create' style={{ color: '#fff' }}>
          Add New User
        </Link>
      </FixedButton>
    </React.Fragment>
  );
}

export default connect(state => state.user, { ...action.user, ...action.globalLoading })(UserList);
