import * as TYPES from '../action-type';
import { getAdmin, getUser } from '../../utils/auth';
export default function personReducer(
  state = {
    auth: getAdmin() ? 'admin' : getUser() ? 'user' : false,
    admin_username: getAdmin(),
    user_id: getUser()
  },
  action
) {
  switch (action.type) {
    case TYPES.ADMIN_LOGIN:
      state = { ...state, auth: 'admin', admin_username: getAdmin() };
      break;
    case TYPES.USER_LOGIN:
      state = { ...state, auth: 'user', user_id: getUser() };
      break;
    case TYPES.ADMIN_LOGOUT:
      state = { admin_username: undefined, auth: false, user_id: undefined };
      break;
    case TYPES.USER_LOGOUT:
      state = { admin_username: undefined, auth: false, user_id: undefined };
      break;
    case TYPES.REMOVE_AUTH:
      state = { admin_username: undefined, auth: false, user_id: undefined };
      break;
    default:
      break;
  }
  return state;
}
