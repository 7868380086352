import React from 'react';

import {withRouter} from 'react-router-dom';
import {Typography, Grid} from '@material-ui/core';
import {Avatar} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import styles from '../../components/layout/AccountStyle/accountStyle';

const useStyles = makeStyles(styles);

function OneAccount({account, history}) {
    const classes = useStyles();
    const goToDetail = id => {
        history.push(`account_detail?account_id=${id}`);
    };
    return (
        <Grid container justify='center'>
            <Grid item md={4} sm={8} xs={12} lg={4}>
                <div
                    className={classes.card}
                    onClick={() => {
                    goToDetail(account.id);
                }}>

                    <div style={{
                        display: 'flex'
                    }}>
                        <Avatar className={classes.avatarImg} alt='ad-img' src={account.logo}/>
                        <Typography className={classes.title} gutterBottom>
                            {account.name}
                        </Typography>
                    </div>
                    <div className={classes.goTo}>
                        <KeyboardArrowRightIcon fontSize='large'/>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}
export default withRouter(OneAccount);
