export function changeLoans(loans) {
  return loans.map(i => {
    let a = { ...i, account_name: i.account ? i.account.name : '', account_logo: i.account ? i.account.logo : '' };

    return a;
  });
}

export function createAccountList(accounts) {
  const accountList = [];
  accounts.forEach(i => {
    accountList.push({ value: i.id, label: i.name });
  });
  return accountList;
}

export function createUserList(users) {
  return {
    borrowerList: users['borrower'].map(i => ({ value: i.id, label: i.first_name + ' ' + i.last_name })),
    managerList: users['manager'].map(i => ({ value: i.id, label: i.first_name + ' ' + i.last_name })),
    brokerList: [
      { value: '', label: 'No Broker' },
      ...users['broker'].map(i => ({ value: i.id, label: i.first_name + ' ' + i.last_name }))
    ]
  };
}

export function findLoans(loans, loan_ids) {
  return loans.filter(i => loan_ids.some(loan_id => loan_id === i.id));
}
