import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  settingList: {
    justifyContent: 'space-between',
    paddingBottom: '0.2rem',
    paddingTop: '0.2rem',
    alignItems: 'center',
    display: 'flex',
    color: 'rgba(0, 0, 0, 0.45)',
    width: '100%',
    fontWeight:'normal',
    background: 'white',
    borderBottom: '1px #ddd solid',
  },
  labelText: {
    fontSize: '14px',
    marginLeft: '3%',
    whiteSpace:'nowrap'
  },
  input: {
    fontSize:'14px',
    lineHeight:'2.2',
    borderWidth:'0',
    width: '100%',
    marginRight: '2%',
    textAlign: 'right',
    '&::-webkit-input-placeholder': {
      color: '#ddd'
    }
  }
});

export default function CustomInput({ label, placeholder, value, onChange, required, type = 'text' }) {
  const classes = useStyles();

  return (
    <div className={classes.settingList}>
      <span className={classes.labelText}>
        {label}
      </span>
      <input className={classes.input} value={value} onChange={onChange} type={type} placeholder={placeholder} />
    </div>
  );
}
