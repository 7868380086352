const admin = 'admin_username';

export function getAdmin() {
  return localStorage.getItem(admin);
}
export function setAdmin(username) {
  localStorage.setItem(admin, username);
}
export function removeAdmin() {
  localStorage.removeItem(admin);
}

const user = 'user_id';

export function getUser() {
  return localStorage.getItem(user);
}

export function setUser(userid) {
  localStorage.setItem(user, userid);
}
export function removeUser() {
  localStorage.removeItem(user);
}
