import React from 'react';
import LoanDetail from './LoanDetail';
import action from '../../store/action';
import { connect } from 'react-redux';
function LoanDetailIndex({ location, auth, remove_auth, history }) {
  if (!auth) {
    remove_auth();
  }
  return (
    <React.Fragment>
      <LoanDetail auth={auth} location={location} history={history} />
    </React.Fragment>
  );
}

export default connect(state => state.person, action.person)(LoanDetailIndex);
