import React from 'react';
import action from '../store/action';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
function AuthWrapper({ auth, remove_auth, history, children }) {
  if (!auth) {
    remove_auth();
  } else if (auth !== 'admin') {
    history.push('/loan');
  }
  return <React.Fragment>{auth === 'admin' && children}</React.Fragment>;
}

export default connect(state => state.person, action.person)(withRouter(AuthWrapper));
