import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  loginForm: {
    width: '100%',
    backgroundColor: 'white',
    maxWidth: 600,
    border:'none'
  }
});

export default function CustomFrom({ children }) {
  const classes = useStyles();
  return (
    <form action='' className={classes.loginForm}>
      {children}
    </form>
  );
}
