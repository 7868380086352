import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

// import ImageUpload from '../../components/ImageUpload';
import CustomForm from '../../components/CustomForm';
import CustomInput from '../../components/CustomInput';
import FixedButton from '../../components/FixedButton';
import useFormInput from '../../hooks/useFormInput';

import action from '../../store/action';
import { connect } from 'react-redux';

import { updateAccountApi, getAccountApi } from '../../api/account';
import { Avatar, Grid } from '@material-ui/core';

import styles from '../../components/layout/AccountStyle/accountStyle';

const useStyles = makeStyles(styles);

function AccountDetails({ global_action_loading, update_account, history, location }) {
  const classes = useStyles();
  const [account, setAccount] = useState({
    name: '',
    address: '',
    phone: '',
    solicitor_name: '',
    solicitor_email: '',
    solicitor_phone: '',
    account_phone: '',
    account_email: '',
    account_name: ''
  });
  const name = useFormInput(account.name);
  const address = useFormInput(account.address);
  const phone = useFormInput(account.phone);
  const solicitor_name = useFormInput(account.solicitor_name);
  const solicitor_email = useFormInput(account.solicitor_email);
  const solicitor_phone = useFormInput(account.solicitor_phone);
  const account_email = useFormInput(account.account_email);
  const account_phone = useFormInput(account.account_phone);
  const account_name = useFormInput(account.account_name);

  const account_id = Number(location.search.split('=')[1]);
  useEffect(() => {
    global_action_loading();
    getAccountApi(account_id)
      .then(res => {
        global_action_loading(false);
        if (res.data.status === 200) {
          setAccount(account => ({
            ...account,
            ...res.data.data
          }));
        } else {
          history.goBack();
        }
      })
      .catch(err => {
        global_action_loading(false);
        history.goBack();
      });
  }, [global_action_loading, account_id, history]);

  const submitAccount = () => {
    if (!phone.value || !name.value || !address.value) {
      alert('wrong');
      return;
    }
    global_action_loading();
    const data = {
      id: account_id,
      phone: phone.value,
      name: name.value,
      address: address.value,
      solicitor_email: solicitor_email.value,
      solicitor_phone: solicitor_phone.value,
      solicitor_name: solicitor_name.value,
      account_email: account_email.value,
      account_phone: account_phone.value,
      account_name: account_name.value
    };

    updateAccountApi(data)
      .then(res => {
        if (res.data.status === 200) {
          update_account(data);
          history.goBack();
        }
        alert(res.data.message);
        global_action_loading(false);
      })
      .catch(err => {
        console.log(err);
        alert('Error');
        global_action_loading(false);
      });
  };

  return (
    <React.Fragment>
      <Grid container justify='center'>
        <Grid item md={4} sm={8} xs={12} lg={4}>
          <div className={classes.headerSpace}>
            {account.logo && <Avatar className={classes.bigAvatar} alt='ad-img' src={account.logo} />}
          </div>
          <br />
          <CustomForm>
            <CustomInput {...name} label='Name' placeholder='Account Name' required />
            <CustomInput {...address} label='Address' placeholder='Account Address' />
            <CustomInput {...phone} label='Phone' placeholder='Account Phone' />
          </CustomForm>
          <div className={classes.sectionTitle}>Solicitor</div>
          <CustomForm>
            <CustomInput {...solicitor_name} label='Name' placeholder='Enter solicitor name' required />
            <CustomInput {...solicitor_email} label='Email' placeholder='Enter solicitor email' />
            <CustomInput {...solicitor_phone} label='Phone' placeholder='Enter solicitor phone' />
          </CustomForm>
          <div className={classes.sectionTitle}>Account</div>
          <CustomForm>
            <CustomInput {...account_name} label='Name' placeholder='Enter account name' required />
            <CustomInput {...account_email} label='Email' placeholder='Enter account email' />
            <CustomInput {...account_phone} label='Phone' placeholder='Enter account phone' />
          </CustomForm>
        </Grid>
      </Grid>
      <FixedButton onClick={submitAccount}>Update</FixedButton>
    </React.Fragment>
  );
}

export default connect(null, {
  ...action.globalLoading,
  ...action.account
})(withRouter(AccountDetails));
