export const SET_ACCOUNTS = 'SET_ACCOUNTS';
export const SET_USERS = 'SET_USERS';
export const ADD_USER = 'ADD_USER';
export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_LOANS = 'SET_LOANS';
export const ADD_LOAN = 'ADD_LOAN';
export const SET_SEARCH_LOANS = 'SET_SEARCH_LOANS';
export const CANCEL_SEARCHING = 'CANCEL_SEARCHING';
export const GLOBAL_LOADING = 'GLOBAL_LOADING';
export const ADMIN_LOGIN = 'ADMIN_LOGIN';
export const USER_LOGIN = 'USER_LOGIN';
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';
export const USER_LOGOUT = 'USER_LOGOUT';
export const REMOVE_AUTH = 'REMOVE_AUTH';
export const NEED_GET = 'NEED_GET';
