import React from 'react';

import { withRouter } from 'react-router-dom';

import { Grid, Avatar } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import styles from '../../components/layout/UserStyle/userStyle';

const useStyles = makeStyles(styles);

function OneUser({ user, history }) {
  const classes = useStyles();
  const goToDetail = id => {
    history.push(`user_detail?user_id=${id}`);
  };
  return (
    <Grid container justify='center'>
      <Grid item md={4} sm={8} xs={12} lg={4}>
        <div
          onClick={() => {
            goToDetail(user.id);
          }}
          className={classes.card}
        >
          <div
            style={{
              display: 'flex'
            }}
          >
            <Avatar className={classes.avatarImg} alt='ad-img' src={user.avatar} />
            <div className={classes.name}>
              <div>
                {user.first_name} {user.last_name}
              </div>
              <div className={classes.nameCompany}>
                {' '}
                {user.account_name}
                {' · '}
                {user.title}
              </div>
              <div className={classes.loginInfo}>Last Login:</div>
            </div>
          </div>
          <div>
            {user.actived ? (
              <button className={classes.stateActive}>Active</button>
            ) : (
              <button className={classes.statePending}>Disabled</button>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
export default withRouter(OneUser);
