import React from 'react';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  settingList: {
    justifyContent: 'space-between',
    paddingBottom: '0.5rem',
    paddingTop: '0.5rem',
    paddingRight: '10px',
    alignItems: 'center',
    display: 'flex',
    color: 'rgba(0, 0, 0, 0.45)',
    width: '100%',
    fontWeight: 'normal',
    background: 'white',
    borderBottom: '1px #ddd solid'
  },
  labelText: {
    fontSize: '14px',
    marginLeft: '3%',
    whiteSpace: 'nowrap'
  },
  stateActive: {
    padding: 5,
    width: '62px',
    height: '22px',
    border: '1px solid #1890ff',
    borderRadius: '4px',
    color: '#1890ff',
    background: '#e6f7ff',
    marginLeft: '4px'
  },
  statePending: {
    padding: 5,
    width: '62px',
    height: '22px',
    border: '1px solid #ffbb96',
    borderRadius: '4px',
    textAlign: 'center',
    color: '#ffbb96',
    background: '#fff2e8',
    marginLeft: '4px'
  }
});

const AntSwitch = withStyles(theme => ({
  root: {
    width: 44,
    height: 22,
    padding: 0,
    display: 'flex'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(22px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#1890ff',
        borderColor: '#1890ff'
      }
    }
  },
  thumb: {
    width: 18,
    height: 18,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: '11px',
    opacity: 1,
    backgroundColor: theme.palette.common.white
  },
  checked: {}
}))(Switch);

export default function CustomSwitch({ label, placeholder, value, onChange, required, type = 'text', actived }) {
  const classes = useStyles();

  return (
    <div className={classes.settingList}>
      <span className={classes.labelText}>
        {label}
        {actived ? (
          <span className={classes.stateActive}>Active</span>
        ) : (
          <span className={classes.statePending}>Disabled</span>
        )}
      </span>

      <AntSwitch checked={actived} onChange={onChange} />
    </div>
  );
}
