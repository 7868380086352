/*eslint-disable*/
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import action from '../../../store/action';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Fab } from '@material-ui/core/';

import routes from 'routes';
import styles from './headerLinksStyle.js';
import { logoutAdminApi } from '../../../api/admin';

const useStyles = makeStyles(styles);

function HeaderLinks({ global_action_loading, admin_logout, user_logout, auth, need_get }) {
  const classes = useStyles();
  const userLogout = () => {
    global_action_loading();
    logoutAdminApi().then(res => {
      admin_logout();
      user_logout();
      need_get();
      global_action_loading(false);
    });
  };
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        {routes
          .filter(route => route.name && (!route.auth || route.auth === auth))
          .map((route, index) => (
            <Link key={index} variant='button' to={route.path} color='transparent' className={classes.navLink}>
              {route.name}
            </Link>
          ))}
        <Fab variant='extended' onClick={userLogout} color='default' className={classes.fab}>
          {'Logout'}
        </Fab>
      </ListItem>
    </List>
  );
}

export default connect(state => state.person, {
  ...action.person,
  ...action.globalLoading,
  ...action.loan
})(HeaderLinks);
