import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { calculateTwoFullDate } from '../../utils/date';
const useStyles = makeStyles({
  card: {
    padding: '1.5%',
    margin: '0 2% 8px 2%',
    border: '1px solid #eeeeee',
    borderRadius: '8px',
    background: 'white'
  },
  title: {
    fontSize: 14
  },
  avatarImg: {
    width: 40,
    height: 40,
    marginLeft: 4,
    marginTop: 4
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '90%',
    marginLeft: '2%'
  },
  name: {
    display: 'flex',
    fontSize: '14px',
    fontWeight: 'bold'
  },
  date: {
    display: 'flex',
    fontSize: 12,
    justifyContent: 'space-between',
    color: 'rgba(0,0,0,0.8)'
  },
  number: {
    fontSize: 16,
    marginBottom: 5,
    fontWeight: '500',
    color: 'rgba(0,0,0,0.8)'
  },
  numberStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.45)',
    fontSize: 12
  }
});

function OneLoan({ loan, history }) {
  const classes = useStyles();

  const goToDetail = id => {
    history.push(`loan_detail?loan_id=${id}`);
  };

  return (
    <div className={classes.card}>
      <div
        className={classes.actionArea}
        onClick={() => {
          goToDetail(loan.id);
        }}
      >
        <div
          style={{
            display: 'flex'
          }}
        >
          <Avatar
            className={classes.avatarImg}
            alt='ad-img'
            src={loan.account_logo ? loan.account_logo : loan.account.logo}
          />
          <div className={classes.info}>
            <div className={classes.name}>{loan.account_name ? loan.account_name : loan.account.name}</div>
            <div className={classes.date}>
              <div>Open: {loan.open_date}</div>
              <div>Maturity: {loan.maturity_date}</div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: 20
          }}
        >
          <div className={classes.numberStyle}>
            <div className={classes.number}>{parseFloat((loan.interest_rate * 100).toFixed(3)) + '%'}</div>
            <div>Interest Rate</div>
          </div>
          <div className={classes.numberStyle}>
            <div className={classes.number}>{'$' + parseFloat(loan.current_balance.toFixed(2)).toLocaleString()}</div>
            <div>Current Balance</div>
          </div>{' '}
          <div className={classes.numberStyle}>
            <div className={classes.number}>{calculateTwoFullDate(loan.maturity_date)}</div>
            <div>Days To Maturity</div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(OneLoan);
