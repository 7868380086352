import { CircularProgress } from '@material-ui/core';
import React from 'react';

const loadingGlobalOpacity = props => {
  return props.loading ? (
    <div
      style={{
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
        opacity: 0.6,
        position: 'fixed',
        zIndex: 999999,
        textAlign: 'center'
      }}
    >
      <CircularProgress style={{ color: '#494e8f', marginTop: '40vh' }} />
    </div>
  ) : null;
};
export default loadingGlobalOpacity;
