import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, TextField, Fab } from '@material-ui/core';
import { setAdmin, setUser } from '../../utils/auth';
import useFormInput from '../../hooks/useFormInput';
import { loginAdminApi } from '../../api/admin';
import { loginUserApi } from '../../api/person';
import { connect } from 'react-redux';
import action from '../../store/action';
import CustomForm from '../../components/CustomForm';
import CustomSelect from '../../components/CustomSelect';

const styles = {
  container: {
    width: '90%',
    alignItems: 'center',
    margin: '25vh auto'
  },

  input: {
    margin: '3% auto'
  },
  fab: {
    marginTop: '15vh',
    paddingLeft: '20%',
    paddingRight: '20%',
    color: 'white',
    background: '#007bff'
  }
};

const useStyles = makeStyles(styles);

function Login({ global_action_loading, admin_login, user_login }) {
  const classes = useStyles();

  const username = useFormInput('');
  const password = useFormInput('');
  const [role, setRole] = useState('admin');
  const handleLogin = () => {
    global_action_loading();
    if (role === 'admin') {
      loginAdminApi({ username: username.value, password: password.value }).then(res => {
        if (res.data.status === 200) {
          setAdmin(username.value);
          admin_login();
          global_action_loading(false);
        } else {
          alert('wrong');
        }
      });
    } else {
      loginUserApi({ email: username.value, password: password.value }).then(res => {
        if (res.data.status === 200) {
          setUser(res.data.data);
          user_login();
          global_action_loading(false);
        } else {
          alert('wrong');
        }
      });
    }
  };

  return (
    <Grid container className={classes.container} justify='center'>
      <Grid item xs={12} sm={10} md={4} lg={4}>
        <div style={{ marginBottom: '30px' }}>
          <Typography variant='h5' align='center'>
            Vincent Capital
          </Typography>
        </div>
        <CustomForm>
          <CustomSelect
            value={role}
            onChange={event => {
              setRole(event.target.value);
            }}
            label='Role'
            options={[
              {
                value: 'user',
                label: 'User'
              },
              {
                value: 'admin',
                label: 'Admin'
              }
            ]}
          />
        </CustomForm>
        <TextField fullWidth={true} placeholder='Enter your Email' className={classes.input} {...username} />
        <TextField fullWidth={true} placeholder='Enter your password' className={classes.input} {...password} />
        <div
          style={{
            textAlign: 'center'
          }}
        >
          <Fab variant='extended' onClick={handleLogin} className={classes.fab}>
            Log In
          </Fab>
        </div>
      </Grid>
    </Grid>
  );
}
export default connect(null, {
  ...action.person,
  ...action.globalLoading
})(Login);
