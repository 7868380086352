import * as TYPES from '../action-type';

export default {
  set_accounts(accounts) {
    return {
      type: TYPES.SET_ACCOUNTS,
      accounts
    };
  },
  add_account(account) {
    return {
      type: TYPES.ADD_ACCOUNT,
      account
    };
  },
  update_account(account) {
    return {
      type: TYPES.UPDATE_ACCOUNT,
      account
    };
  }
};
