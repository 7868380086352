import { _post, _get } from './index';

//loan
export const createLoanApi = data => {
  let req = {
    data,
    url: 'loan/make_loan'
  };
  return _post(req);
};

export const createTransactionApi = data => {
  let req = {
    data,
    url: 'loan/create_transaction'
  };
  return _post(req);
};

//get loan
export const getLoanApi = data => {
  let req = {
    data,
    url: `loan/get_loan/${data.id}`
  };
  return _get(req);
};

export const searchLoansApi = data => {
  let req = {
    data,
    url: 'loan/search_loans'
  };
  return _get(req);
};

export const getLoansApi = data => {
  let req = {
    data,
    url: 'loan/get_loans'
  };
  return _get(req);
};

export const getMyLoansApi = data => {
  let req = {
    data,
    url: 'loan/my_loans'
  };
  return _get(req);
};
