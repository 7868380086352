import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import action from '../../store/action';

import OneLoan from './OneLoan';
import { Grid } from '@material-ui/core';
import FixedButton from '../../components/FixedButton';
import { getLoanMethod } from '../../utils/api';
function LoanList({ global_action_loading, loans, set_loans, loansIsGet, auth, searchingLoans, searching }) {
  useEffect(() => {
    if (!loansIsGet) {
      global_action_loading();
      getLoanMethod(set_loans, global_action_loading);
    }
  }, [loansIsGet, set_loans, global_action_loading]);

  return (
    <React.Fragment>
      <Grid container justify='center'>
        <Grid item md={6} sm={8} xs={12} lg={4}>
          {searching ? (
            <div style={{ width: '100%' }}>
              {searchingLoans.length > 0 && searchingLoans.map(i => <OneLoan loan={i} key={i.id} />)}
            </div>
          ) : (
            <React.Fragment>
              {' '}
              <div style={{ width: '100%' }}>{loans.length > 0 && loans.map(i => <OneLoan loan={i} key={i.id} />)}</div>
            </React.Fragment>
          )}     
        </Grid>
      </Grid>
      <FixedButton onClick={() => {}}>
            {' '}
            <Link to='/loan_create' style={{ color: '#fff' }}>
              {' '}
              Add A New Loan{' '}
            </Link>
          </FixedButton>
    </React.Fragment>
  );
}

export default connect(state => state.loan, { ...action.loan, ...action.globalLoading })(LoanList);
