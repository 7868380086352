import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
import { connect } from 'react-redux';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';

import Avatar from '@material-ui/core/Avatar';
// @material-ui/icons
import Menu from '@material-ui/icons/Menu';
// core components
import styles from './headerStyle.js';
import BackButton from '../../BackButton';
const useStyles = makeStyles(styles);

function Header(props) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener('scroll', headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener('scroll', headerColorChange);
      }
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body.getElementsByTagName('header')[0].classList.remove(classes[color]);
      document.body.getElementsByTagName('header')[0].classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body.getElementsByTagName('header')[0].classList.add(classes[color]);
      document.body.getElementsByTagName('header')[0].classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const { color, rightLinks, leftLinks, brand, fixed, absolute, canBack, title } = props;

  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });
  const brandComponent = <Button className={classes.title}>{brand}</Button>;
  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden mdUp>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            {canBack ? <BackButton /> : <div style={{ width: '13%' }}></div>}
            <div style={{ color: '#333', fontWeight: '600' }}>{title}</div>
            <IconButton color='default' aria-label='open drawer' onClick={handleDrawerToggle}>
              <Menu />
            </IconButton>
          </div>
        </Hidden>
        <Hidden smDown implementation='css'>
          {brandComponent}{' '}
        </Hidden>
        <Hidden smDown implementation='css'>
          {rightLinks}
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation='js'>
        <Drawer
          variant='temporary'
          anchor={'right'}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          onClick={handleDrawerToggle}
          onClose={handleDrawerToggle}
        >
          <div className={classes.appResponsive}>
            <div>
              {/* <div className={classes.arrow}>
                <Button variant='text' color='default' onClick={handleDrawerToggle}>
                  <ArrowBackIosIcon />
                </Button>
              </div> */}
              <div className={classes.titleUser}>
                <Typography variant='h5' component='div' align='center'>
                  Vincent Capital
                </Typography>
              </div>
              <div className={classes.flexBox}>
                <Avatar
                  alt='Remy Sharp'
                  src='https://cdn3.vectorstock.com/i/1000x1000/38/27/male-face-avatar-logo-template-pictogram-vector-11333827.jpg'
                  className={classes.avatar}
                />
              </div>
              <div>
                <Typography
                  variant='body1'
                  align='center'
                  style={{
                    margin: '2%'
                  }}
                >
                  Name
                </Typography>
                {/* <Typography
                                    variant='body2'
                                    align='center'
                                    style={{
                                    color: 'gray'
                                }}>Admin</Typography> */}
              </div>
            </div>
            <div>
              {leftLinks}
              {rightLinks}
            </div>
          </div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
}

Header.defaultProp = {
  color: 'dark'
};

Header.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'transparent', 'white', 'rose', 'dark']),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.object,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'transparent', 'white', 'rose', 'dark'])
      .isRequired
  })
};

export default connect(null)(Header);
