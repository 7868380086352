import { _post } from './index';

export const loginAdminApi = data => {
  let req = {
    data,
    url: 'admin/login'
  };
  return _post(req);
};

export const logoutAdminApi = () => {
  let req = {
    url: 'admin/logout'
  };
  return _post(req);
};
