import React, { useState, useEffect } from 'react';

import CustomForm from '../../components/CustomForm';
import CustomInput from '../../components/CustomInput';
import CustomSwitch from '../../components/CustomSwitch';

import FixedButton from '../../components/FixedButton';
import useFormInput from '../../hooks/useFormInput';
import action from '../../store/action';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUserApi, updateUserApi } from '../../api/user';

import { Grid, Avatar } from '@material-ui/core';
import { validEmail } from '../../utils/regex';

import { makeStyles } from '@material-ui/core/styles';
import styles from '../../components/layout/UserStyle/userStyle';

import OneLoan from '../LoanList/OneLoan';
import LoanList from '../LoanList';

import { findLoans } from '../../utils/utils';
import { getLoanMethod } from '../../utils/api';

const useStyles = makeStyles(styles);

function UserDetails({ loansIsGet, set_loans, loans, global_action_loading, update_user, location, history }) {
  const classes = useStyles();
  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    title: '',
    email: '',
    phone: '',
    solicitor_phone: '',
    password: '',
    company: '',
    actived: false
  });

  const first_name = useFormInput(user.first_name);
  const last_name = useFormInput(user.last_name);
  const title = useFormInput(user.title);
  const email = useFormInput(user.email);
  const phone = useFormInput(user.phone);
  const password = useFormInput(user.password);
  const company = useFormInput(user.company);

  const user_id = Number(location.search.split('=')[1]);

  const [actived, setActived] = useState(true);
  const [loan_ids, setLoanIds] = useState([]);
  const [userLoans, setUserLoans] = useState([]);

  const [loanListDisplay, setLoanListDisplay] = useState(false);
  useEffect(() => {
    if (!loansIsGet) {
      getLoanMethod(set_loans, global_action_loading);
    }
  }, [loansIsGet, set_loans, global_action_loading]);
  const pushLoanToUser = id => {
    setLoanIds(loan_ids.filter(loan_id => loan_id !== id));
    setUserLoans(userLoans.filter(loan => loan.id !== id));
  };

  useEffect(() => {
    setUserLoans(findLoans(loans, loan_ids));
  }, [loans, loan_ids]);

  useEffect(() => {
    global_action_loading();
    getUserApi(user_id)
      .then(res => {
        global_action_loading(false);

        if (res.data.status === 200) {
          const { data } = res.data;
          setUser(user => ({
            ...user,
            ...data
          }));
          setActived(data.actived ? data.actived : false);
          setLoanIds(data.loan_ids ? data.loan_ids : []);
        } else {
          history.goBack();
        }
      })
      .catch(err => {
        global_action_loading(false);
        history.goBack();
      });
  }, [global_action_loading, user_id, history]);

  const submitUser = () => {
    if (!phone.value || !last_name.value || !first_name.value || !email.value || !password.value) {
      alert('Please Enter Required Field');
      return;
    }
    if (!validEmail(email.value)) {
      alert('Wrong Email Format');
      return;
    }

    global_action_loading();
    const data = {
      id: user.id,
      title: title.value,
      company: company.value,
      phone: phone.value,
      first_name: first_name.value,
      last_name: last_name.value,
      email: email.value,
      password: password.value,
      actived,
      loan_ids
    };

    updateUserApi(data)
      .then(res => {
        if (res.data.status === 200) {
          update_user(data);
          history.goBack();
        }
        alert(res.data.message);
        global_action_loading(false);
      })
      .catch(err => {
        alert('Error');
        global_action_loading(false);
      });
  };

  return (
    <React.Fragment>
      <Grid container justify='center'>
        <Grid item md={4} sm={8} xs={12} lg={4}>
          <div
            style={{
              display: loanListDisplay ? 'none' : 'block'
            }}
          >
            <div
              style={{
                width: '100%',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              {user.avatar && <Avatar className={classes.bigAvatar} alt='ad-img' src={user.avatar} />}
            </div>
            <br />
            <CustomForm>
              <CustomInput {...first_name} label='First Name' placeholder='First Name' required />
              <CustomInput {...last_name} label='Last Name' placeholder='Last Name' required />
              <CustomInput {...company} label='Company' placeholder='Company' required />
              <CustomInput {...title} label='Title' placeholder='Title In Company' required />
              <CustomInput {...phone} label='Phone' placeholder='User Phone' required />
              <CustomInput {...email} label='Email' placeholder='Email Address For Login' required />
              <CustomInput {...password} label='Password' placeholder='Password' required />
              <CustomSwitch
                label='Status'
                actived={actived}
                onChange={() => {
                  setActived(!actived);
                }}
              />
            </CustomForm>
            <div
              style={{
                marginLeft: '5%',
                width: '90%',
                color: '#6e6f6f',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              loan access{' '}
              <button
                onClick={() => {
                  setLoanListDisplay(true);
                }}
                style={{ color: 'blue', fontSize: 20 }}
              >
                +
              </button>
            </div>
            <div style={{ width: '100%' }}>
              {userLoans.length > 0 &&
                userLoans.map(i => (
                  <OneLoan
                    pushLoanToUser={pushLoanToUser}
                    checked={loan_ids.some(item => i.id === item)}
                    loan={i}
                    key={i.id}
                  />
                ))}
            </div>
          </div>
          <div
            style={{
              display: loanListDisplay ? 'block' : 'none'
            }}
          >
            <LoanList
              onClick={() => {
                setLoanListDisplay(false);
              }}
              loan_ids={loan_ids}
              setLoanIds={setLoanIds}
              loans={userLoans}
            />
          </div>
        </Grid>
      </Grid>
      <FixedButton onClick={submitUser}>Update User</FixedButton>
    </React.Fragment>
  );
}

export default connect(state => state.loan, { ...action.globalLoading, ...action.user, ...action.loan })(
  withRouter(UserDetails)
);
