import React from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles({
  settingList: {
    justifyContent: 'space-between',
    paddingBottom: '0.2rem',
    paddingTop: '0.2rem',
    alignItems: 'center',
    display: 'flex',
    color: 'rgba(0, 0, 0, 0.45)',
    width: '100%',
    fontWeight:'normal',
    background: 'white',
    borderBottom: '1px #ddd solid'
  },
  labelText: {
    fontSize: '0.9rem',
    marginLeft: '3%'
  },

  select: {
    fontSize:'14px',
    lineHeight:'1.4',
    marginRight: '2%',
    textAlign: 'right'
  }
});

export default function CustomInput({ label, options = [], value, onChange, required }) {
  const classes = useStyles();

  return (
    <div className={classes.settingList}>
      <span className={classes.labelText}>
        {label}
      </span>
      <Select disableUnderline={true} value={value} onChange={onChange} className={classes.select} IconComponent={KeyboardArrowRightIcon}>
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
