import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';

const useStyles = makeStyles({
  card: {
    padding: '1.5%',
    margin: '0 2% 8px 2%',
    border: '1px solid #eeeeee',
    borderRadius: '8px',
    background: 'white'
  },
  title: {
    fontSize: 14
  },
  avatarImg: {
    width: 40,
    height: 40,
    marginLeft: 4,
    marginTop: 4
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '90%',
    marginLeft: '2%'
  },
  name: {
    display: 'flex',
    fontSize: '14px',
    fontWeight: 'bold'
  },
  date: {
    display: 'flex',
    fontSize: 12,
    justifyContent: 'space-between',
    color: 'rgba(0,0,0,0.8)'
  },
  number: {
    fontSize: 16,
    marginBottom: 5,
    fontWeight: '500',
    color: 'rgba(0,0,0,0.8)'
  },
  numberStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.45)',
    fontSize: 12
  }
});

function OneLoan({ loan, pushLoanToUser, checked }) {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      <div className={classes.actionArea}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              display: 'flex'
            }}
          >
            <Avatar className={classes.avatarImg} alt='ad-img' src={loan.account_logo} />
            <div className={classes.info}>
              <div className={classes.name}>{loan.account_name}</div>

              <div style={{ color: '#aaa' }}>{loan.id}</div>
            </div>
          </div>
          <input
            checked={checked}
            type='checkbox'
            onChange={() => {
              pushLoanToUser(loan.id);
            }}
          />
        </div>
      </div>
    </div>
  );
}
export default OneLoan;
