import React from 'react';
import LoanList from './LoanList';
// import LoanSearch from './LoanSearch';
import action from '../../store/action';
import { connect } from 'react-redux';

function Loan(props) {
  return (
    <React.Fragment>
      {/* <LoanSearch searching={searching} />
      <br /> */}
      <LoanList {...props} />
    </React.Fragment>
  );
}

export default connect(state => state.person, action.person)(Loan);
