import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  avatarWrapper: {
    alignItems: 'center',
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  settingList: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    color: '#6f6e6e',
    width: 80,
    height: 80,
    fontSize: '0.39rem',
    borderRadius: '50%',
    background: 'white'
  },
  avatarImg: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: 80,
    height: 80,
    borderRadius: '50%'
  },
  img: { marginRight: '0.35rem', height: '1.3rem', width: '1.3rem', borderRadius: '50%' }
});

export default function ImageUpload({ file, setFile }) {
  const classes = useStyles();
  function handlePicChange(ev) {
    if (!ev.target.files[0]) {
      return;
    }
    let file = ev.target.files[0];
    if (file.type.indexOf('image') < 0) {
      return;
    }
    if (window.Blob) {
      const fileName = file.name;
      const reader = new FileReader();
      reader.onload = e => {
        const img = document.createElement('img');
        img.src = e.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const MAX_WIDTH = 100;
          const MAX_HEIGHT = 100;
          let width = img.width;
          let height = img.height;
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          ctx.canvas.toBlob(
            blob => {
              file = new File([blob], fileName, {
                type: 'image/jpeg',
                lastModified: Date.now()
              });
              file.url = img.src;
              setFile(file);
            },
            'image/jpeg',
            1
          );
        };
      };
      reader.readAsDataURL(file);
    } else if (file.size > 1024 * 1024 * 3) {
      setFile('');
    } else {
      setFile(file);
    }
  }
  return (
    <div className={classes.avatarWrapper}>
      <div className={classes.settingList}>
        <label className={classes.label} htmlFor='file'>
          {!file && 'Upload'}
          {file && <img className={classes.avatarImg} alt='ad-img' src={file.url} />}
          <input id='file' type='file' style={{ display: 'none' }} onChange={e => handlePicChange(e)} />
        </label>
      </div>
    </div>
  );
}
