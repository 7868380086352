import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import Layout from './components/layout/index';
import GlobalLoading from './components/GlobalLoading/index';
import { Provider, connect } from 'react-redux';
import store from './store';
import { createBrowserHistory } from 'history';
import routes from './routes';
import Login from './views/Login';

const history = createBrowserHistory();

const App = React.memo(props => {
  const { globalLoading } = props;

  return (
    <React.Fragment>
      <GlobalLoading loading={globalLoading} />
      <Router history={history}>
        <Layout>
          <Switch>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} component={route.component} />
            ))}
            <Redirect to='/loan' />
          </Switch>
        </Layout>
      </Router>
    </React.Fragment>
  );
});

const ConnectAppPage = connect(state => state.globalLoading)(App);

function Root(props) {
  const { auth } = props;

  return <React.Fragment>{auth ? <ConnectAppPage /> : <Login />}</React.Fragment>;
}

const ConnectRoot = connect(state => state.person)(Root);

export default class extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectRoot />
      </Provider>
    );
  }
}
