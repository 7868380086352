import { getAccountsApi } from '../api/account';
import { getUsersApi } from '../api/user';
import { getLoansApi, getMyLoansApi } from '../api/loan';
import { getAdmin } from './auth';

export function getAccountMethod(set_accounts, global_action_loading) {
  getAccountsApi()
    .then(res => {
      if (res.data.status === 200) {
        set_accounts(res.data.data);
      } else {
        alert(res.data.message);
      }
      global_action_loading(false);
    })
    .catch(err => {
      alert('Error');
      global_action_loading(false);
    });
}

export function getUserMethod(set_users, global_action_loading) {
  getUsersApi()
    .then(res => {
      if (res.data.status === 200) {
        set_users(res.data.data);
      } else {
        alert(res.data.message);
      }
      global_action_loading(false);
    })
    .catch(err => {
      alert('Error');
      global_action_loading(false);
    });
}

export function getLoanMethod(set_loans, global_action_loading) {
  if (getAdmin()) {
    getLoansApi()
      .then(res => {
        if (res.data.status === 200) {
          set_loans(res.data.data);
        } else {
          alert(res.data.message);
        }
        global_action_loading(false);
      })
      .catch(err => {
        alert('Error');
        global_action_loading(false);
      });
  } else {
    getMyLoansApi()
      .then(res => {
        if (res.data.status === 200) {
          set_loans(res.data.data);
        } else {
          alert(res.data.message);
        }
        global_action_loading(false);
      })
      .catch(err => {
        alert('Error');
        global_action_loading(false);
      });
  }
}
