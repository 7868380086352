import { defaultFont } from '../material-kit-react';

import tooltip from './tooltipsStyle.js';

const headerLinksStyle = theme => ({
  list: {
    ...defaultFont,
    fontSize: '14px',
    margin: '0 auto 2% auto',
    paddingLeft: '0',
    listStyle: 'none',
    paddingTop: '0',
    paddingBottom: '0',
    color: 'inherit'
  },
  fixAll: {
    minHeight: 'calc(100vh - 184px)'
  },
  listItem: {
    float: 'left',
    color: 'inherit',
    position: 'relative',
    display: 'block',
    width: 'auto',
    margin: '0',
    padding: '0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      '&:after': {
        width: 'calc(100% - 30px)',
        content: '""',
        display: 'block',
        height: '1px',
        marginLeft: '15px',
        backgroundColor: '#e5e5e5'
      }
    }
  },
  listItemText: {
    padding: '0 !important'
  },
  navLink: {
    color: 'rgba(0,0,0,0.8)',
    position: 'relative',
    padding: '0.2rem 0.9375rem',
    fontWeight: '700',
    fontSize: '14px',
    borderRadius: '3px',
    lineHeight: '1.5',
    textDecoration: 'none',
    margin: '0px',
    display: 'inline-flex',
    '&:hover,&:focus': {
      color: 'rgba(0,0,0,0.8)',
      background: 'rgba(200, 200, 200, 0.3)'
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 30px)',
      marginLeft: '15px',
      marginBottom: '8px',
      marginTop: '0',
      textAlign: 'left',
      '& > span:first-child': {
        justifyContent: 'flex-start'
      }
    }
  },
  notificationNavLink: {
    color: 'inherit',
    padding: '0.9375rem',
    fontWeight: '400',
    fontSize: '12px',
    textTransform: 'uppercase',
    lineHeight: '20px',
    textDecoration: 'none',
    margin: '0px',
    display: 'inline-flex',
    top: '4px'
  },
  registerNavLink: {
    top: '3px',
    position: 'relative',
    fontWeight: '400',
    fontSize: '12px',
    textTransform: 'uppercase',
    lineHeight: '20px',
    textDecoration: 'none',
    margin: '0px',
    display: 'inline-flex'
  },
  navLinkActive: {
    color: 'inherit',
    backgroundColor: 'rgba(255, 255, 255, 0.1)'
  },
  icons: {
    width: '20px',
    height: '20px',
    marginRight: '3px'
  },
  socialIcons: {
    position: 'relative',
    fontSize: '20px !important',
    marginRight: '4px'
  },
  dropdownLink: {
    '&,&:hover,&:focus': {
      color: 'inherit',
      textDecoration: 'none',
      display: 'block',
      padding: '10px 20px'
    }
  },
  ...tooltip,
  marginRight5: {
    marginRight: '5px'
  },
  center: {
    textAlign: 'center'
  },
  fab: {
    color: 'white',
    position: 'relative',
    padding: '0 2em',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    textDecoration: 'none',
    margin: '0px',
    display: 'inline-flex',
    '&:hover,&:focus': {
      color: 'inherit',
      background: 'rgba(200, 200, 200, 0.3)'
    },
    [theme.breakpoints.down('sm')]: {
      width: '10rem',
      padding: '0 3.5em',
      marginLeft: '8vw',
      marginBottom: '8px',
      marginTop: '20vh',
      '& > span:first-child': {
        justifyContent: 'flex-start'
      }
    },
    background: '#1a75d2'
  },
  headerWidth: {
    minHeight: '10vh',
    width: '100%',
    background: 'transparent',
    [theme.breakpoints.down('sm')]: {
      minHeight: '8vh',
      width: '100%',
      background: 'transparent'
    }
  }
});

export default headerLinksStyle;
