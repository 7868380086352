import React from 'react';
import Header from './Header/Header';
import HeaderLinks from './Header/HeaderLinks';

import styles from './Header/headerLinksStyle';
import { Link, withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);
const title = pathname => {
  switch (pathname) {
    case '/account':
      return 'Account List';
    case '/user':
      return 'User List';
    case '/loan':
      return 'Loan List';
    case '/account_create':
      return 'Create Account';
    case '/user_create':
      return 'Create User';
    case '/loan_create':
      return 'Create Loan';
    case '/account_detail':
      return 'Account Update';
    case '/user_detail':
      return 'User Update';
    case '/loan_detail':
      return 'Loan Detail';
    default:
      return 'Loan management';
  }
};

function Layout(props) {
  const classes = useStyles();
  const {
    location: { pathname }
  } = props;
  const goBack = pathname !== '/account' && pathname !== '/loan' && pathname !== '/user';

  return (
    <React.Fragment>
      <div className={classes.fixAll}>
        <Header
          brand={
            <Link to='/index' className={classes.navLink}>
              {' '}
              <div>Loan System</div>{' '}
            </Link>
          }
          canBack={goBack}
          title={title(pathname)}
          rightLinks={<HeaderLinks />}
          fixed
          color='transparent'
          changeColorOnScroll={{
            height: 15,
            color: 'white'
          }}
          {...props}
        />
        <div className={classes.headerWidth}></div>
        <div>{props.children}</div>
        <div style={{ height: '5rem' }}></div>
      </div>
    </React.Fragment>
  );
}
export default withRouter(Layout);
