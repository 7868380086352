import * as TYPES from '../action-type';

export default function account(
  state = {
    accounts: [],
    accountsIsGet: false
  },
  action
) {
  switch (action.type) {
    case TYPES.ADD_ACCOUNT:
      state = JSON.parse(JSON.stringify(state));
      state.accounts = [...state.accounts, action.account];
      break;
    case TYPES.UPDATE_ACCOUNT:
      state = JSON.parse(JSON.stringify(state));
      state.accounts = state.accounts.map(i => {
        if (i.id === action.account.id) {
          return { ...i, ...action.account };
        } else {
          return i;
        }
      });
      break;
    case TYPES.SET_ACCOUNTS:
      state = JSON.parse(JSON.stringify(state));
      state.accounts = action.accounts;
      state.accountsIsGet = true;
      break;
    default:
      break;
  }
  return state;
}
