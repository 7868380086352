import React from 'react';
import AccountList from './AccountList';
import AdminAuthWrapper from '../../hoc/AdminAuthWrapper';
function Account() {
  return (
    <AdminAuthWrapper>
      <AccountList />
    </AdminAuthWrapper>
  );
}

export default Account;
