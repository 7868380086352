import { _post, _get, _put } from './index';

export const createUserApi = data => {
  let req = {
    data,
    url: 'admin/create_user'
  };
  return _post(req);
};

export const getUserApi = id => {
  let req = {
    url: `admin/get_user/${id}`
  };
  return _get(req);
};

export const updateUserApi = data => {
  let req = {
    data,
    url: `admin/update_user/${data.id}`
  };
  return _put(req);
};

export const getUsersApi = data => {
  let req = {
    data,
    url: 'admin/get_users'
  };
  return _get(req);
};
