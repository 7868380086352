import React from 'react';
import CreateFrom from './CreateForm';
import AdminAuthWrapper from '../../hoc/AdminAuthWrapper';
function AccountCreateIndex() {
  return (
    <AdminAuthWrapper>
      <CreateFrom />
    </AdminAuthWrapper>
  );
}

export default AccountCreateIndex;
