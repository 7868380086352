import * as TYPES from '../action-type';
import { removeAdmin, removeUser } from '../../utils/auth';
const personRedux = {
  admin_login() {
    return {
      type: TYPES.ADMIN_LOGIN
    };
  },
  user_login() {
    return {
      type: TYPES.USER_LOGIN
    };
  },
  admin_logout() {
    removeAdmin();
    return {
      type: TYPES.ADMIN_LOGOUT
    };
  },
  user_logout() {
    removeUser();
    return {
      type: TYPES.USER_LOGOUT
    };
  },
  remove_auth() {
    return {
      type: TYPES.REMOVE_AUTH
    };
  }
};

export default personRedux;
