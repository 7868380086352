import React, { useEffect } from 'react';
import FixedButton from '../../components/FixedButton';
import { connect } from 'react-redux';
import action from '../../store/action';
import { getAccountMethod } from '../../utils/api';
import OneAccount from './OneAccount';

import { Link } from 'react-router-dom';
function AccountList(props) {
  const { global_action_loading, accounts, set_accounts, accountsIsGet } = props;

  useEffect(() => {
    if (!accountsIsGet) {
      getAccountMethod(set_accounts, global_action_loading);
    }
  }, [accountsIsGet, set_accounts, global_action_loading]);

  return (
    <React.Fragment>
      <React.Fragment>
        <div style={{ width: '100%' }}>
          {accounts.map(i => (
            <OneAccount account={i} key={i.id} />
          ))}
        </div>
      </React.Fragment>

      <FixedButton onClick={() => {}}>
        {' '}
        <Link to='/account_create' style={{ color: '#fff' }}>
          {' '}
          Add Account{' '}
        </Link>
      </FixedButton>
    </React.Fragment>
  );
}

export default connect(state => state.account, { ...action.account, ...action.globalLoading })(AccountList);
