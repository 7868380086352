import React from 'react';
import UserList from './UserList';
import AdminAuthWrapper from '../../hoc/AdminAuthWrapper';
function User() {
  return (
    <AdminAuthWrapper>
      <UserList />
    </AdminAuthWrapper>
  );
}

export default User;
