const userStyle = theme => ({
    card: {
        margin: '9px 15px 0',
        border: '1px solid #eeeeee',
        borderRadius: '8px',
        background: 'white',
        height: '92px',
        padding: '13px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    name: {
        fontSize: '14px',
        color: 'rgba(0,0,0,0.85)',
        fontWeight: '500',
        marginLeft:'5px',
    },
    nameCompany:{
        fontSize: '12px',
        color: 'rgba(0,0,0,0.85)',
        fontWeight: 'normal',
    },
    loginInfo:{
        fontSize: '12px',
        color: 'rgba(0,0,0,0.45)',
        fontWeight: 'normal',
    },
    avatarImg: {
        width: 40,
        height: 40,
        margin:'3px'
    },
    stateActive: {
      width:'62px',
      height:'22px',
      border:'1px solid #1890ff',
      borderRadius:'4px',
      color:'#1890ff',
      background:'#e6f7ff'
    },
    statePending: {
        width:'62px',
        height:'22px',
        border:'1px solid #ffbb96',
        borderRadius:'4px',
        textAlign:'center',
        color:'#ffbb96',
        background:'#fff2e8'
      },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    headerTitle: {
        fontSize: '18px',
        color: 'rgba(0, 0, 0, 0.85)',
        fontWeight: 'normal',
        marginLeft: '-6vw'
    },
    headerSpace: {
        marginTop: '2vh',
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    },
    bigAvatar: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        width: 60,
        height: 60
    },
    sectionTitle: {
        fontSize: '14px',
        fontWeight: '500',
        color: 'rgba(0, 0, 0, 0.85)',
        margin: '0 0.7rem 1rem'
    }
})

export default userStyle;