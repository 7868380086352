import { _post, _get, _put } from './index';

export const createAccountApi = data => {
  let req = {
    data,
    url: 'admin/create_account'
  };
  return _post(req);
};

export const getAccountApi = id => {
  let req = {
    url: `admin/get_account/${id}`
  };
  return _get(req);
};

export const updateAccountApi = data => {
  let req = {
    data,
    url: `admin/update_account/${data.id}`
  };
  return _put(req);
};

export const getAccountsApi = data => {
  let req = {
    data,
    url: 'admin/get_accounts'
  };
  return _get(req);
};
